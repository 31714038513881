.order-modal-window .ant-modal {
  width: 80vw !important;
  max-width: 1100px;
  height: 80vh;
}
.order-modal-window .ant-modal .modal-footer .total-price {
  margin: 0 auto 0 0;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.order-modal-window .ant-modal .work-section {
  flex: 1;
  padding: 20px 0;
  overflow: auto;
}
.order-modal-window .ant-modal .work-section > .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
}
.order-modal-window .ant-modal .work-section > .row > div {
  flex: 1;
}
.order-modal-window .ant-modal .work-section > .row .client-field {
  min-width: 30%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.order-modal-window .ant-modal .work-section > .row .client-field div {
  flex: 1;
  margin: 0 10px 0 0;
}
.order-modal-window .ant-modal .work-section > .row .client-field .btn {
  font-size: 32px;
}
.order-modal-window .ant-modal .work-section section {
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 0;
}
.order-modal-window .ant-modal .work-section section .section-header {
  color: #fff;
  border-bottom: 1px solid #121212;
  padding: 0 0 10px;
  margin: 0 0 20px;
}
.order-modal-window .ant-modal .work-section section .works-list {
  display: flex;
  flex-direction: column;
}
.order-modal-window .ant-modal .work-section section .works-list .row {
  display: flex;
  border-bottom: 1px solid #121212;
}
.order-modal-window .ant-modal .work-section section .works-list .row .th {
  flex: 1;
  border-right: 1px solid #121212;
}
.order-modal-window .ant-modal .work-section section .works-list .row .th:last-of-type {
  border: 0;
}
.order-modal-window .ant-modal .work-section section .works-list .row .th input,
.order-modal-window .ant-modal .work-section section .works-list .row .th .ant-select-selector {
  background: none;
  outline: none !important;
  border: 0 !important;
  box-shadow: none;
}
.order-modal-window .ant-modal .work-section section .works-list .row .index {
  min-height: 100%;
  max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.order-modal-window .ant-modal .work-section section .works-list .row .price {
  max-width: 200px;
}
.order-modal-window .ant-modal .work-section section .works-list .row .mechanic {
  max-width: 100px;
}
.order-modal-window .ant-modal .work-section section .total-row {
  height: 50px;
}
.order-modal-window .ant-modal .work-section section .total-row .name {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 0 10px;
}
.order-modal-window .ant-modal .work-section section .total-row .name svg {
  margin: 0 10px 0 0;
}
.order-modal-window .ant-modal .work-section section .total-row .price {
  min-width: 300px;
  display: flex;
  align-items: center;
  padding: 0 0 0 12px;
  font-weight: bold;
  font-size: 18px;
}
