header.header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #1D1E20;
}
header.header .logo {
  width: 60px;
  height: 60px;
  cursor: pointer;
}
header.header .logo img {
  width: 100%;
}
header.header .menu {
  cursor: pointer;
}
.ant-dropdown li svg {
  margin: 0 5px 0 0;
}
.ant-dropdown li span {
  font-size: 16px;
}
