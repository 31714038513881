.client-modal-window .ant-modal {
  width: 60vw;
  max-width: 700px;
  height: 60vh;
}
.client-modal-window .work-section {
  padding: 20px 0;
}
.client-modal-window .work-section > .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
}
.client-modal-window .work-section > .row > div {
  flex: 1;
}
.client-modal-window .work-section section {
  margin: 40px 0 0 0;
}
.client-modal-window .work-section .section-header {
  color: #fff;
  border-bottom: 1px solid #121212;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px;
}
.client-modal-window .work-section .section-header .btn {
  font-size: 20px;
}
.client-modal-window .work-section .motorcycles-list {
  display: flex;
  flex-direction: column;
}
.client-modal-window .work-section .motorcycles-list .row {
  display: flex;
  border-bottom: 1px solid #121212;
}
.client-modal-window .work-section .motorcycles-list .row .th {
  flex: 1;
  border-right: 1px solid #121212;
}
.client-modal-window .work-section .motorcycles-list .row .th:last-of-type {
  border: 0;
}
.client-modal-window .work-section .motorcycles-list .row .th div {
  width: 100%;
}
.client-modal-window .work-section .motorcycles-list .row .th input {
  background: none;
  border: 0;
  padding: 5px 10px;
  outline: none !important;
  box-shadow: none !important;
  color: #fff;
  font-size: 16px;
}
.client-modal-window .work-section .motorcycles-list .row .index {
  min-height: 100%;
  max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
