.login-page {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-page form {
  width: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.login-page form::before {
  content: '';
  height: 6px;
  position: absolute;
  left: 30px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background: #35363B;
  border-radius: 4px 0 0 4px;
}
.login-page form input {
  height: 70px;
  background: #1D1E20;
  border: 0;
  color: #8d9099;
  padding: 0 12px 0 30px;
  outline: none;
  font-size: 20px;
}
.login-page form input:nth-child(1) {
  border-radius: 10px 10px 0 0;
}
.login-page form input:nth-child(2) {
  border-radius: 0 0 10px 10px;
}
.login-page form button {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #1D1E20;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 6px solid #35363B;
  color: #35363B;
  font-size: 30px;
  position: absolute;
  right: -25px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.login-page form button:hover {
  border: 4px solid #35363B;
  color: #8d9099;
  font-size: 35px;
}
