.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;

  .table {
    padding: 30px;
    opacity: .8;
    flex: 1;
    overflow: hidden;

    .table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        margin: 0;
      }
    }

    .actions {
      .btn {
        margin: 0 0 0 10px;
      }
    }
  }
}