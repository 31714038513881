.client-modal-window {
  .ant-modal {
    width: 60vw;
    max-width: 700px;
    height: 60vh;
  }

  .work-section {
    padding: 20px 0;

    & > .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 20px;

      & > div {
        flex: 1;
      }
    }

    section {
      margin: 40px 0 0 0;
    }

    .section-header {
      color: #fff;
      border-bottom: 1px solid #121212;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 20px;

      .btn {
        font-size: 20px;
      }
    }

    .motorcycles-list {
      display: flex;
      flex-direction: column;

      .row {
        display: flex;
        border-bottom: 1px solid #121212;

        .th {
          flex: 1;
          border-right: 1px solid #121212;

          &:last-of-type {
            border: 0;
          }

          div {
            width: 100%;
          }

          input {
            background: none;
            border: 0;
            padding: 5px 10px;
            outline: none !important;
            box-shadow: none !important;
            color: #fff;
            font-size: 16px;
          }
        }

        .index {
          min-height: 100%;
          max-width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
      }
    }
  }
}